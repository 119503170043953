.divider26 {
  height: 100%;
  width: 100%;
  position: absolute;
  margin: 0 !important;
  top: 0;
  left: 0;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-top: 2px solid #e6e6e6;
  box-sizing: border-box;
  opacity: 0.45;
}
.divider-line3,
.summary-frame2 {
  display: flex;
  flex-direction: row;
  max-width: 100%;
}
.summary-frame2 {
  height: 0;
  flex: 1;
  align-items: center;
  justify-content: center;
  position: relative;
}
.divider-line3 {
  align-self: stretch;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 10px;
  box-sizing: border-box;
}
.john-smith7 {
  position: relative;
  line-height: 130%;
  color: #e5b638;
}
.john-smithtext {
  white-space: nowrap;
}
.canada1,
.john-smithtext,
.st-churhc-street {
  position: relative;
  line-height: 130%;
}
.canada1 {
  font-weight: 500;
}
.street-address-text {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 5px 0;
  min-width: 412px;
  max-width: 100%;
}
.material-symbols-lightedit-ou-icon1 {
  height: 22px;
  width: 22px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  
}
.buttons-states-dark-instance,
.st-churchstreet-vancover-frame,
.summary9 {
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
  /* margin-top: 10px; */
  font-size: var(--primary-font-size-mini);
font-family: var(--primary-font-family);


}

.buttons-states-dark-instance:hover{
  border:1px solid var(--hp-yellow-600);
  transition: border 0.3s ease;
}
.st-churchstreet-vancover-frame {
  align-self: stretch;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0 10px;
}
.buttons-states-dark-instance,
.summary9 {
  border-radius: 10px;
  flex-direction: column;
  
}
.buttons-states-dark-instance {
  /* align-self: stretch; */
  border: 1px solid #707070;
  box-sizing: border-box;
  padding: 20px 21px 20px 19px;
}
.summary9 {
  width: 708px;
  overflow: hidden;
  font-size: 14px;
  
}
.button68 {
  position: relative;
  font-size: 16px;
  line-height: 130%;
  font-family: Inter;
  color: #fff;
  text-align: center;
}
.buttons-states-dark56 {
  cursor: pointer;
  border: 0;
  padding: 14px 4px 14px 12px;
  background-color: #c21f24;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  white-space: nowrap;
  margin-top: 15px;
}
.buttons-states-dark56:hover {
  background-color: #e8454a;
}
.epback-frame1 {
  width: 1320px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 20px;
  box-sizing: border-box;
  gap: 16px 0;
  max-width: 100%;
  text-align: left;
  font-size: 36px;
  color: #fff;
  font-family: Poppins;
}
@media screen and (max-width: 800px) {
  .street-address-text {
    min-width: 100%;
  }
}
