.orders-icon {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 24px;
}
.my-orders {
  flex: 1;
  position: relative;
  line-height: 130%;
  display: inline-block;
  max-width: 100%;
}
.right-icon1 {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.orders-parent {
  width: 600px;
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 20px;
  box-sizing: border-box;
  gap: 0 10px;
  text-align: left;
  font-size: 18px;
  color: #fff;
  font-family: Poppins;
}
