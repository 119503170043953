.menu-icon {
  width: 24px;
  position: relative;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 0;
}
.button4 {
  z-index: 1;
}
.b,
.button4 {
  position: relative;
  line-height: 130%;
}
.wrapper {
  margin: 0 !important;
  position: absolute;
  top: -10px;
  right: 0;
  border-radius: 10px;
  background-color: #e5b638;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 5px;
  z-index: 2;
  color: #000;
}
.property-1outlineicon {
  border-radius: 6px;
  border: 3px solid #e5b638;
  box-sizing: border-box;
  height: 49px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 0 12px;
  position: relative;
  gap: 0 10px;
  text-align: center;
  font-size: 16px;
  color: #fff;
  font-family: Inter;
}
.property-1outlineicon:hover{
  background-color: #b38205 !important;
  border: 3px solid #b38205 !important;
  box-sizing: border-box;
}