.frame-parent {
  display: flex;
  flex-wrap: wrap;
}

.map-pin-group-wrapper {
  flex: 0 0 calc(20% - 20px); /* Set each button to occupy 20% of the container width with 20px gap between buttons */
}

.frame-parent65 {
  max-width: 100%;
  width: 100%;
  overflow: hidden;
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two cards per row */
  gap: 20px; /* Adjust gap as needed */
  justify-items: center; /* Center cards horizontally */
}

.category-frame {
  display: flex;
  flex-direction: column;
  font-size: var(--primary-font-size);
  font-family: var(--primary-font-family);
}
.category-2-wrapper {
  border: 1px solid rgb(54, 54, 54);
  transition: border-color 0.3s ease; /* Smooth transition for border color */
}

.category-2-wrapper:hover {
  border-color: rgb(223, 183, 81); /* Change border color on hover */
}

.category-2-wrapper:hover .alberta9 {
  color: rgb(223, 183, 81);
  transition: color 0.3s ease; /* Smooth transition for text color */
}

.category-2-child,
.our-delivery-partners {
  text-align: left;
  margin-top: 20px;
  font-family: var(--primary-font-family-bold);
  font-size: var(--primary-font-size);
}

.category-2-child,
.frame-child {
  align-self: stretch;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  flex-shrink: 0;
}

/* .our-delivery-partners {
  position: relative;
  font-weight: 600;
} */

.frame-child {
  height: 60px;
  width: 60px;
  position: relative;
  object-fit: cover;
}

/* .frame-parent {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
} */




@media screen and (max-width: 800px) {
  .category-27,
  .category-28 {
    padding-top: 26px;
    padding-bottom: 26px;
    box-sizing: border-box;
  }
}

@media screen and (max-width: 450px) {
  .category-28 {
    margin-top: 30px;
  }
}



.hospital-st-fort7{
  font-family: var(--primary-font-family);
  font-size: var(--primary-font-size-mini);
}

.category-2-inner2{
  text-align: left;
}
