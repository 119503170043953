.order-summary2 {
  margin: 0;
  font-size: inherit;
  font-weight: 600;
  font-family: inherit;
}
.div32,
.order-summary2,
.subtotal3 {
  position: relative;
  line-height: 130%;
}
.div32 {
  text-align: right;
  white-space: nowrap;
}
.subtotal-parent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
}
.choose-your-method1,
.shipping6 {
  position: relative;
  line-height: 130%;
}
.shipping6 {
  font-weight: 600;
}
.choose-your-method1 {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
}
.text-frame1 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px 0;
  min-width: 265px;
  max-width: 100%;
}
.text-frame2 {
  position: relative;
  line-height: 130%;
  text-align: right;
  white-space: nowrap;
}
.text-frame-parent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  row-gap: 20px;
  max-width: 100%;
}
.div33,
.tax3 {
  position: relative;
  line-height: 130%;
}
.div33 {
  text-align: right;
  white-space: nowrap;
}
.tax-parent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
}
.div34,
.total3 {
  position: relative;
  line-height: 130%;
  font-weight: 600;
}
.div34 {
  text-align: right;
  white-space: nowrap;
}
.total-parent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
}
.frame-parent63 {
  align-self: stretch;
  justify-content: flex-start;
  gap: 15px 0;
  font-size: 16px;
}
.container-frame,
.frame-parent63,
.summary10 {
  /* font-size: var(--sub-header-font-size); */
font-family: var(--primary-font-family);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 100%;
}
.container-frame {
  align-self: stretch;
  justify-content: flex-start;
  gap: 30px 0;
}
.summary10 {
  width: 500px;
  border-radius: 24px;
  background-color: #363636;
  /* border: 0.5px solid var(--hp-yellow-600); */
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
  justify-content: center;
  padding: 23px 26px 23px 22px;
  min-width: 500px;
  text-align: left;
  font-size: var(--sub-header-font-size);
  font-family: var(--primary-font-family-bold);

  color: #fff;
  /* font-family: Poppins; */
}
@media screen and (max-width: 1150px) {
  .summary10 {
    flex: 1;
  }
}
@media screen and (max-width: 800px) {
  .summary10 {
    min-width: 100%;
  }
}
@media screen and (max-width: 450px) {
  .order-summary2 {
    font-size: var(--primary-font-size);
/* font-family: var(--primary-font-family-bold); */

    line-height: 25px;
  }
  .subtotal-parent,
  .text-frame-parent,
  .total-parent {
    flex-wrap: wrap;
  }
}
.buttonstyle{
  right: 120px;
}
@media screen and (max-width: 450px){
  .summary10{
  margin-top: 30px;
  }
}