.what-we-believe4 {
  font-family: var(--primary-font-family-bold);
  color: var(--hp-yellow-600);
  margin: 0;
  position: relative;
  /* font-size: inherit; */
  line-height: 160%;
  text-transform: uppercase;
  font-weight: 700;
  /* font-family: inherit; */
}
.what-we-believe-frame {
  font-family: var(--primary-font-family);
  height: 53px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0;
  box-sizing: border-box;
}
.heading-item {
  width: 10px;
  height: 5px;
  position: relative;
  border-radius: 20px;
  background-color: #e5b638;
}
.heading4 {
  font-family: var(--primary-font-family);
  
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.view-all-stores2 {
  font-family: var(--primary-font-family);
  position: relative;
  line-height: 160%;
}
.right-icon2 {
  
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 40px;
}
.our-delivery-partners5 {
  font-family: var(--primary-font-family);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 0 10px;
}
.contact-us-frame {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2px 0 0;
  font-size: 24px;
  color: #e5b638;
}
.review-form {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  text-align: left;
  font-size: 36px;
  color: #fff;
  /* font-family: Poppins; */
}
@media screen and (max-width: 800px) {
  .what-we-believe4 {
    font-size: 29px;
    line-height: 46px;
  }
}
@media screen and (max-width: 450px) {
  .what-we-believe4 {
    font-size: 22px;
    line-height: 35px;
  }
  .view-all-stores2 {
    font-size: 19px;
    line-height: 31px;
  }
}
