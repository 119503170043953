.profile-icon {
  height: 24px;
  width: 24px;
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 24px;
}
.contact-information {
  flex: 1;
  position: relative;
  line-height: 130%;
  font-weight: 500;
  display: inline-block;
  min-width: 114px;
  max-width: 100%;
}
.down-icon4 {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.divider13,
.item-quantity {
  box-sizing: border-box;
  max-width: 100%;
}
.item-quantity {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 19px;
  gap: 0 10px;
  flex-shrink: 0;
}
.divider13 {
  width: 709px;
  height: 1px;
  position: relative;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-top: 1px solid #e6e6e6;
  display: none;
  opacity: 0.45;
}
.button49,
.ordering-for {
  align-self: stretch;
  position: relative;
  line-height: 130%;
}
.ordering-for {
  height: 21px;
  font-weight: 500;
  display: flex;
  align-items: flex-end;
}
.button49 {
  flex: 1;
  font-size: 12px;
  font-family: Poppins;
  color: #fff;
  text-align: center;
}
.button49,
.button50,
.buttons-states-dark37 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.buttons-states-dark37 {
  cursor: pointer;
  border: 0;
  padding: 5px 20px;
  background-color: #c21f24;
  height: 26px;
  width: 62px;
  border-radius: 6px;
  flex-direction: row;
  box-sizing: border-box;
}
.buttons-states-dark37:hover {
  background-color: #e8454a;
}
.button50 {
  align-self: stretch;
  width: 39px;
  position: relative;
  font-size: 12px;
  line-height: 130%;
  font-family: Inter;
  color: #fff;
  text-align: center;
}
.buttons-states-dark38,
.remove-from-cart-button {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.buttons-states-dark38 {
  cursor: pointer;
  border: 1px solid #fff;
  padding: 5px 19px;
  background-color: transparent;
  height: 26px;
  flex: 1;
  border-radius: 6px;
  box-sizing: border-box;
  justify-content: center;
}
.buttons-states-dark38:hover {
  background-color: rgba(230, 230, 230, 0.09);
  border: 1px solid #e6e6e6;
  box-sizing: border-box;
}
.remove-from-cart-button {
  width: 151px;
  justify-content: flex-start;
  gap: 0 10px;
}
.cart-total {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0 19px;
  gap: 10px 0;
  flex-shrink: 0;
  font-size: 16px;
}
.contact-detail {
  width: 102px;
  font-weight: 500;
}
.cart-details-container,
.contact-detail,
.john-smith4 {
  height: 18px;
  position: relative;
  line-height: 130%;
  display: flex;
  align-items: flex-end;
}
.john-smith4 {
  width: 79px;
}
.cart-details-container {
  align-self: stretch;
  white-space: nowrap;
}
.tax-label {
  align-self: stretch;
  flex-direction: column;
  gap: 10px 0;
}
.delivery-method-icon,
.delivery-method-icon-wrapper,
.tax-label {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.delivery-method-icon {
  width: 115px;
  flex-direction: column;
  gap: 10px 0;
}
.delivery-method-icon-wrapper {
  align-self: stretch;
  flex-direction: row;
  padding: 0 19px;
}
.divider14 {
  width: 708px;
  height: 0;
  position: relative;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-top: 1px solid #e6e6e6;
  box-sizing: border-box;
  opacity: 0.45;
  max-width: 100%;
}
.button51 {
  height: 16px;
  width: 40px;
  position: relative;
  line-height: 130%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.buttons-states-dark39 {
  height: 36px;
  width: 80px;
  border-radius: 6px;
  border: 1px solid #fff;
  box-sizing: border-box;
  flex-direction: row;
  padding: 10px 2px 2px;
}
.button52,
.buttons-states-dark39,
.buttons-states-dark40 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.button52 {
  height: 16px;
  width: 50px;
  position: relative;
  line-height: 130%;
}
.buttons-states-dark40 {
  height: 36px;
  flex: 1;
  border-radius: 6px;
  background-color: #c21f24;
  flex-direction: row;
  padding: 10px 2px 2px;
  box-sizing: border-box;
  font-family: Poppins;
}
.edoneil-avvd-zlh-dow-aunsplash1 {
  width: 180px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0;
  box-sizing: border-box;
  gap: 0 10px;
}
.contactinformation,
.edoneil-avvd-zlh-dow-aunsplash-wrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 19px;
  text-align: center;
  font-size: 12px;
  font-family: Inter;
}
.contactinformation {
  height: 60px;
  border-radius: 10px;
  background-color: #363636;
  border: 1px solid #545454;
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
  flex-direction: column;
  align-items: center;
  padding: 20px 0 0;
  gap: 20px 0;
  max-width: 100%;
  text-align: left;
  font-size: 14px;
  color: #fff;
  font-family: Poppins;
}
@media screen and (max-width: 800px) {
  .item-quantity {
    flex-wrap: wrap;
  }
  .contactinformation {
    height: auto;
  }
}
