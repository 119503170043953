.button {
  position: relative;
  line-height: 130%;
}
.property-1filledprimary {
  border-radius: 6px;
  background-color: #c21f24;
  height: 49px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 12px;
  box-sizing: border-box;
  text-align: center;
  font-size: 16px;
  color: #fff;
  font-family: Inter;
}
.property-1filledprimary:hover {
  background-color: var(--hp-yellow-600) !important;
  border: 1px solid var(--hp-yellow-600) !important;
  box-sizing: border-box;
  transition: background-color 0.3s ease;
  transition: color 0.3s ease;
  color: black !important;
}
.property-1filledprimary.button-design{
  display: inline-block;
  font-family: Poppins;
  text-transform: unset;
  font-weight: unset;
  font-size: 18px;
  right: 230px;
}
.property-1filledprimary.button {
  display: inline-block;
  font-family: Inter;
  text-transform: unset;
  font-weight: unset;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
