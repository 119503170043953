.darkmode-item {
  width: 1669px;
  height: 633px;
  position: absolute;
  margin: 0 !important;
  right: 1px;
  bottom: -55.5px;
  object-fit: contain;
}
.fotter-text{
  color:'white'
}
.fotter-text:hover{
  color:var(--hp-yellow-600)
}
.whatsapp-image-2024-01-29-at-12 {
  height: 106px;
  width: 106px;
  position: relative;
  border-radius: 150px;
  object-fit: cover;
}
.whatsapp-image-2024-01-29-at-13 {
  height: 64px;
  width: 322px;
  position: relative;
  object-fit: cover;
  max-width: 100%;
}
.instagram-dark,
.instagram-dark-wrapper {
  
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  max-width: 100%;
}
.instagram-dark {
  gap: 0 10px;
  
}
.instagram-dark-wrapper {
  min-width: 409px;
}
.facebook-dark-icon1,
.instagram-dark-icon1,
.mail-dark-icon1 {
  height: 40px;
  width: 40px;
  position: relative;
  border-radius: 30px;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 40px;
}
.instagram-dark-parent {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 10px 0;
  box-sizing: border-box;
  gap: 0 15px;
  /* min-width: 409px; */
  max-width: 100%;
  margin-left: 1px;
}
.dark-mode-toggle3,
.social-media-icons,
.whats-app-images {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  max-width: 100%;
}
.whats-app-images {
  align-self: stretch;
  flex-wrap: wrap;
  gap: 0 20px;
}
.dark-mode-toggle3,
.social-media-icons {
  gap: 0 30px;
}
.social-media-icons {
  align-self: stretch;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px 0;
  z-index: 1;
}
.phcopyright-thin-icon1 {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 24px;
}
.hunger-points-all1 {
  position: relative;
  line-height: 130%;
}
.become-a-v-i-p,
.darkmode1 {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
}
.become-a-v-i-p {
  flex-direction: row;
  justify-content: end;
  gap: 0 10px;
  z-index: 1;
}
.darkmode1 {
  background-color:var(--card-bg);
  border-top: '1px solid #E4B637';
  /* box-shadow: 0-1px 4px rgba(0, 0, 0, 0.25); */
  overflow: hidden;
  flex-direction: column;
  justify-content: flex-start;
  padding: 32px 80px;
  box-sizing: border-box;
  position: relative;
  gap: 70px 0;
  max-width: 100%;
  text-align: center;
  font-size: 16px;
  color: #fff;
  font-family: Poppins;
}
@media screen and (max-width: 1125px) {
  .dark-mode-toggle3 {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 800px) {
  .instagram-dark {
    flex-wrap: wrap;
    justify-content: center;
    
  }
  .instagram-dark-parent,
  .instagram-dark-wrapper {
    min-width: 100%;
    margin-left: 1px;
  }
  .darkmode1 {
    border-top: '1px solid #E4B637';
    gap: 70px 0;
    padding-left: 40px;
    padding-right: 40px;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .instagram-dark-parent {
    flex-wrap: wrap;
    justify-content: center;
    margin-left: 1px;
  }
  .darkmode1 {
    border-top: '1px solid #E4B637';
    gap: 70px 0;
  }
}
