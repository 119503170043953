/* coming_soon_popup.css */
.popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(5px); /* Apply backdrop blur effect */
}

.popup {
  position: relative;
  /* background-color: red; */
  background-color: transparent;
  padding: 0px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  outline: none; /* Remove default focus outline */
  width: 100%;
}


@media (max-width: 2560px) {
  .popup {
    max-width: 1000px;
  }
}

@media (max-width: 1440px) {
  .popup {
    max-width: 1000px;
  }
}

@media (max-width: 1200px) {
  .popup {
    max-width: 800px;
  }
}

@media (max-width: 992px) {
  .popup {
    max-width: 600px;
  }
}

@media (max-width: 768px) {
  .popup {
    max-width: 600px;
  }
}

@media (max-width: 576px) {
  .popup {
    max-width: 350px;
  }
}
@media (max-width: 375px) { /* New breakpoint for 375px width */
  .popup {
    max-width: 300px;
  }
}
.popup-image {
  width: 100%;
  border-radius: 10px;
  margin-bottom: 20px;
}

.close-button {
  position: absolute;
  top: -20px;
  right: -20px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.popup-content {
  position: relative;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; /* Center content vertically */
}
