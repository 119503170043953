.frame-root-icon,
.image-5-icon {
  position: absolute;
  margin: 0 !important;
}
.frame-root-icon {
  /* width: 924px; */
  height: 1px;
  top: 413px;
  right: 80px;
}
.image-5-icon {
  width: 1440px;
  height: 803px;
  top: calc(50% - 212px);
  left: calc(50% - 720px);
  object-fit: cover;
}
.locationinstances,
.locationinstances1 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  max-width: 100%;
}
.locationinstances1 {
  height: 200px;
  flex: 1;
  overflow: hidden;
  flex-direction: column;
  padding: 45px 80px;
  position: relative;
}
.locationinstances {
  align-self: stretch;
  flex-direction: row;
  padding: 0 0 28px;
}
.alberta5,
.nearby-stores {
  margin: 0;
  position: relative;
  font-size: inherit;
  font-family: inherit;
}
.nearby-stores {
  line-height: 130%;
  font-weight: 700;
}
.alberta5 {
  height: 54px;
  flex: 1;
  font-weight: 600;
  display: inline-block;
  max-width: 100%;
}
.alberta-state-frame {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  max-width: 100%;
  font-size: 36px;
}
.location-icon6 {
  height: 40px;
  width: 40px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.hospital-st-fort4 {
  margin: 0;
  height: 72px;
  flex: 1;
  position: relative;
  font-size: inherit;
  font-weight: 400;
  font-family: inherit;
  display: inline-block;
  min-width: 318px;
  max-width: 100%;
}
.location-parent2 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0 10px;
  max-width: 100%;
}
.call-icon4 {
  height: 40px;
  width: 40px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.div13 {
  flex: 1;
  position: relative;
  display: inline-block;
  min-width: 141px;
  max-width: 100%;
}
.call-parent1 {
  align-self: stretch;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0 10px;
}
.call-parent1,
.hospital-frame,
.location-frame1 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.hospital-frame {
  flex: 1;
  flex-direction: column;
  gap: 15px 0;
}
.location-frame1 {
  align-self: stretch;
  flex-direction: row;
  flex-shrink: 0;
}
.menu-icon6,
.vectorconnectors-icon {
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.vectorconnectors-icon {
  align-self: stretch;
  max-width: 100%;
  max-height: 100%;
}
.menu-icon6 {
  height: 24px;
  width: 24px;
}
.button27 {
  position: relative;
  font-size: 18px;
  line-height: 130%;
  font-weight: 600;
  font-family: Poppins;
  color: #fff;
  text-align: center;
}
.buttons-states-dark19 {
  cursor: pointer;
  border: 0;
  padding: 0 12px;
  background-color: #363636;
  flex: 1;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  gap: 0 20px;
  min-width: 169px;
  min-height: 60px;
  white-space: nowrap;
}
.buttons-states-dark19:hover {
  background-color: #5c5c5c;
}
.basket-icon5 {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.button28 {
  position: relative;
  font-size: 18px;
  line-height: 130%;
  font-family: Poppins;
  color: #fff;
  text-align: center;
}
.buttons-states-dark20 {
  cursor: pointer;
  border: 0;
  padding: 0 12px;
  background-color: #c21f24;
  flex: 1;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  gap: 0 10px;
  min-width: 169px;
  min-height: 60px;
  white-space: nowrap;
}
.buttons-states-dark20:hover {
  background-color: #e8454a;
}
.buttons-states-dark-frame {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0 20px;
  flex-shrink: 0;
}
.category-24,
.ourdeliverypartnerstext {
  align-self: stretch;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  max-width: 100%;
}
.category-24 {
  border-radius: 10px;
  background-color: #363636;
  padding: 40px;
  box-sizing: border-box;
  gap: 30px 0;
}
.ourdeliverypartnerstext {
  cursor: pointer;
  z-index: 1;
  text-align: left;
  font-family: Poppins;
}
.categoryinstance,
.location,
.nearbystorestext {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.categoryinstance {
  flex: 1;
  flex-direction: column;
  gap: 20px 0;
  max-width: 100%;
}
.location,
.nearbystorestext {
  box-sizing: border-box;
}
.nearbystorestext {
  /* width: 780px; */
  flex-direction: row;
  padding: 0 80px;
  max-width: 100%;
}
.location {
  width: 100%;
  height: 100vh;
  position: relative;
  background-color: #252525;
  overflow: hidden;
  flex-direction: column;
  padding: 0 0 119px;
  gap: 12px 0;
  letter-spacing: normal;
  text-align: center;
  font-size: 24px;
  color: #fff;
  font-family: Inter;
}
@media screen and (max-width: 800px) {
  .alberta5 {
    font-size: 29px;
  }
  .category-24 {
    gap: 30px 0;
    padding-top: 26px;
    padding-bottom: 26px;
    box-sizing: border-box;
  }
  .nearbystorestext {
    padding-left: 40px;
    padding-right: 40px;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .nearby-stores {
    font-size: 19px;
    line-height: 25px;
  }
  .alberta5 {
    font-size: 22px;
  }
  .div13,
  .hospital-st-fort4 {
    font-size: 19px;
  }
}