.category-frame {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
  flex-shrink: 0;
  text-align: center;
  font-size: var(--primary-font-size);
  color: #fff;
  font-family: var(--primary-font-family);
}
